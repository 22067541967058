export function useSubscriptionDetails() {
  const {organisation} = useSelectedOrganisation();

  const subcription = computed(() => organisation.value?.abonnement);
  const isStandardSubscription = computed(() => subcription.value === subscriptions.standard);
  const isProSubscription = computed(() => subcription.value === subscriptions.pro);
  const isConnectSubscription = computed(() => subcription.value === subscriptions.connect);
  const isProConnectSubscription = computed(() => subcription.value === subscriptions.pro_connect);
  const isProOrBetterSubscription = computed(() => isProSubscription.value || isConnectSubscription.value || isProConnectSubscription.value);

  return {
    subcription,
    isStandardSubscription,
    isProSubscription,
    isConnectSubscription,
    isProConnectSubscription,
    isProOrBetterSubscription,
  };
}
